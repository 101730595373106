<template>
  <v-dialog
    v-model="show"
    max-width="1440"
  >
    <v-card tile>
      <close-btn @click="show = false" :style="{ marginTop: '12px' }" />

      <v-img
        :src="src"
        aspect-ratio
        contain
      ></v-img>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      show: false,
    }
  },

  props: {
    src: String
  }
}

</script>
